import React from 'react'
import { useDispatch } from 'react-redux'
import CommonOrderBump from 'common/components/entities/OrderBumpNew'
import { OrderBumpInterface } from 'common/types/entities/order-bump-interface'
import ChildrenEntities from 'publisher/components/ChildrenEntities'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import { usePayment } from 'publisher/store'
import { toggleCheckedBump } from 'publisher/store/payment/paymentActions'
import {
  getOrderBumpId,
  isOrderBumpChecked,
} from 'publisher/store/payment/paymentSelectors'

function OrderBump({ entity }: { entity: OrderBumpInterface }) {
  const orderBumpId = usePayment(getOrderBumpId)
  const isBumpChecked = usePayment(isOrderBumpChecked)
  const dispatch = useDispatch()

  const toggleCheckbox = () => {
    dispatch(toggleCheckedBump())
  }

  if (!orderBumpId) {
    return <div />
  }

  return (
    <StyleWrapper
      margin={entity.margin}
      appearance={entity.appearance}
      mobileMargin={entity.mobileMargin}
    >
      <CommonOrderBump
        headerBackgroundColor={entity.headerBackgroundColor}
        mobileHeaderBackgroundColor={entity.mobileHeaderBackgroundColor}
        backgroundColor={entity.backgroundColor}
        mobileBackgroundColor={entity.mobileBackgroundColor}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        title={entity.title}
        attrId={entity.htmlAttrId}
        lineHeight={entity.lineHeight}
        fontSize={entity.fontSize}
        fontStyle={entity.fontStyle}
        fontFamily={entity.fontFamily}
        fontWeight={entity.fontWeight}
        color={entity.color}
        mobileFontSize={entity.mobileFontSize}
        mobileLineHeight={entity.mobileLineHeight}
        mobileFontStyle={entity.mobileFontStyle}
        mobileFontFamily={entity.mobileFontFamily}
        mobileFontWeight={entity.mobileFontWeight}
        mobileColor={entity.mobileColor}
        isArrowHidden={entity.isArrowHidden}
        toggleCheckbox={toggleCheckbox}
        justifyContent={entity.justifyContent}
        mobileJustifyContent={entity.mobileJustifyContent}
        isChecked={isBumpChecked}
      >
        <ChildrenEntities childIds={entity.childIds} />
      </CommonOrderBump>
    </StyleWrapper>
  )
}

export default OrderBump
